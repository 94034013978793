import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IOrderState } from './order.types';

import { TOrderStatus } from 'modules/basket/basket.types';

// Create initial state
export const initialOrderState: IOrderState = {
	eventsInProgress: 0,
	isOrderCreated: false,
	activeOrder: {
		orderId: '',
	},
};

const orderSlice = createSlice({
	name: 'order',
	initialState: initialOrderState,
	reducers: {
		RESET_ORDER_STATE() {
			return { ...initialOrderState };
		},
		CREATE_ORDER(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
				activeOrder: {
					...state.activeOrder,
					status: undefined,
				},
				isOrderCreated: true,
			};
		},
		CREATE_ORDER_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeOrder: {
					...action.payload.data,
					id: action.payload.data.orderId,
				},
			};
		},
		CREATE_ORDER_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				isOrderCreated: false,
			};
		},
		GET_ORDER(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ORDER_SUCCESS(state, action) {
			return {
				...state,
				activeOrder: {
					...action.payload?.data,
					orderId: action.payload?.data?.id,
				},
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ORDER_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ORDER_STATUS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ORDER_STATUS_SUCCESS(state, action) {
			return {
				...state,
				activeOrder: {
					...state.activeOrder,
					status: action.payload?.data?.status,
				},
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ORDER_STATUS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SET_ORDER_CREATED(state, action) {
			return {
				...state,
				isOrderCreated: action.payload,
			};
		},
		SET_ORDER_STATUS(state, action) {
			return {
				...state,
				activeOrder: {
					...state.activeOrder,
					status: action.payload,
				},
			};
		},
		SET_POS_ORDER_ID(state, action) {
			return {
				...state,
				activeOrder: {
					...state.activeOrder,
					posOrderId: action.payload,
				},
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	RESET_ORDER_STATE,
	CREATE_ORDER,
	CREATE_ORDER_FAIL,
	CREATE_ORDER_SUCCESS,
	GET_ORDER,
	GET_ORDER_FAIL,
	GET_ORDER_SUCCESS,
	GET_ORDER_STATUS,
	GET_ORDER_STATUS_SUCCESS,
	GET_ORDER_STATUS_FAIL,
	SET_ORDER_CREATED,
	SET_ORDER_STATUS,
	SET_POS_ORDER_ID,
} = orderSlice.actions;

/** Reset state */
export const resetOrderState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_ORDER_STATE());
};

/** Create order from a basket id */
export const createOrder = (basketId: string) => async (dispatch: Dispatch) => {
	const response = await dispatch(
		CREATE_ORDER({
			request: {
				method: 'post',
				url: '1/order',
				data: {
					basketId,
				},
			},
		}),
	);

	return response?.payload;
};

/** Get order by id */
export const getOrder = (orderId: string) => async (dispatch: Dispatch) => {
	const response = await dispatch(
		GET_ORDER({
			request: {
				method: 'get',
				url: `1/order/${orderId}`,
			},
		}),
	);

	return response.payload?.data;
};

/** Get order status by venue ID and POS ID */
export const getOrderStatus = (venueId: string, posId: string) => async (dispatch: Dispatch) => {
	const response = await dispatch(
		GET_ORDER_STATUS({
			request: {
				method: 'get',
				url: `2/tabs/from-pos/${venueId}/by-pos-id/${posId}/status`,
			},
		}),
	);

	return response.payload?.data as { failed: number, pending: number, successful: number };
};

/** Update order created status */
export const setOrderCreated = (created: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_ORDER_CREATED(created));
};

/** Set order status */
export const setOrderStatus = (status: TOrderStatus) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_ORDER_STATUS(status));
};

export const setPosOrderId = (id: string) => async (dispatch: Dispatch) => {
	await dispatch(SET_POS_ORDER_ID(id));
};

export default orderSlice.reducer;
