import { HTMLMotionProps, motion, MotionProps } from 'framer-motion';
import { rgba } from 'polished';
import * as React from 'react';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { TButtonSizes, TButtonVariants } from './button.types';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Icon from 'components/icons/icon.component';
import icons from 'components/icons/icons.config';

// Interface for component props
interface IComponentProps extends MotionProps {
	ariaLabel?: string;
	size?: TButtonSizes;
	variant?: TButtonVariants;
	icon?: keyof typeof icons;
	iconWidth?: number;
	iconHeight?: number;
	iconColour?: keyof typeof brand;
	iconClassName?: string;
}

const disabledStyling = css`
	opacity: 0.3 !important;
`;

const buttonWithIconStyling = css`
	display: flex;
	align-items: center;
`;

const blackStyling = css`
	background: ${brand.opBlack};
	color: ${brand.white};
`;

const primaryStyling = css`
	background: ${({ theme }) => theme?.colors?.link};
	color: ${({ theme }) => theme?.colors?.lblPrimary};
`;

const secondaryStyling = css`
	background: ${rgba(brand.primary, 0.3)};
	color: ${brand.primary};

	&:hover {
		background: ${rgba(brand.primary, 0.2)};
	}
`;

const ghostStyling = css`
	background: none;
	border: 1px solid ${brand.text};
	color: ${brand.text};
	opacity: 0.8;
`;

const textStyling = css`
	background: none;
	border: none;
	color: ${brand.text};
`;

const tertiaryStyling = css`
	background: ${brand.white};
	color: ${brand.text};

	&:hover {
		background: ${brand.white};
	}
`;

const ghaPimaryStyling = css`
	background: ${brand.ghaPrimary};
	color: ${brand.white};
	font-family: ${fonts.gha.copyFamily};
	text-transform: uppercase;
	font-weight: ${fonts.weights.bold};
	font-size: ${fonts.sizes.standard};
	min-height: 50px;
`;

const ghaSecondaryStyling = css`
	background: ${brand.white};
	color: ${brand.ghaPrimary};
	border: 2px solid ${brand.ghaPrimary};
	font-family: ${fonts.gha.copyFamily};
	text-transform: uppercase;
	font-weight: ${fonts.weights.bold};
	font-size: ${fonts.sizes.standard};
	min-height: 50px;
`;

const ghaBlackStyling = css`
	background: ${brand.black};
	color: ${brand.white};
	border: 2px solid ${brand.black};
	font-family: ${fonts.gha.copyFamily};
	text-transform: uppercase;
	font-weight: ${fonts.weights.bold};
	font-size: ${fonts.sizes.standard};
	min-height: 50px;
`;

const iconStyling = css`
	background: none;

	&:hover {
		background: none;
	}
`;

const lgButtonStyles = css`
	padding: 17px 27px;
`;
const mdButtonStyles = css`
	padding: 12px;
`;
const smButtonStyles = css`
	padding: 0 0.8rem;
	height: 3.5rem;
	font-size: ${fonts.sizes.standard};
	line-height: ${fonts.lineHeight.small};
	display: inline-flex;
	align-items: center;
	justify-content: center;
`;

// Create styled button component
const StyledButton = styled(motion.button)<
	IComponentProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>`
	line-height: 1;
	border: none;
	border-radius: 10px;
	cursor: pointer;
	font-size: ${fonts.sizes.large};
	font-weight: ${fonts.weights.regular};
	text-align: center;
	transition: background 0.2s ease-out;
	outline: none;
	${({ size }) => {
		if (size === 'lg') {
			return lgButtonStyles;
		}
		if (size === 'md') {
			return mdButtonStyles;
		}
		if (size === 'sm') {
			return smButtonStyles;
		}
		return null;
	}}

	&:active {
		border-color: transparent;
		box-shadow: none;
	}

	${({ icon }) => {
		if (icon) {
			return buttonWithIconStyling;
		}
		return null;
	}}

	${({ variant }) => {
		if (variant === 'black') {
			return blackStyling;
		}
		if (variant === 'tertiary') {
			return tertiaryStyling;
		}
		if (variant === 'secondary') {
			return secondaryStyling;
		}
		if (variant === 'ghost') {
			return ghostStyling;
		}
		if (variant === 'icon') {
			return iconStyling;
		}
		if (variant === 'text') {
			return textStyling;
		}
		if (variant === 'ghaPrimary') {
			return ghaPimaryStyling;
		}
		if (variant === 'ghaSecondary') {
			return ghaSecondaryStyling;
		}
		if (variant === 'ghaBlack') {
			return ghaBlackStyling;
		}

		return primaryStyling;
	}}

	${({ disabled }) => {
		if (disabled === true) {
			return disabledStyling;
		}
		return null;
	}}
`;

export const StyledIcon = styled(Icon)`
	margin-right: 10px;
`;

// TODO: add support for as="a" so that this can be a link and not a button element

/** Renders button component */
const Button = forwardRef<
	HTMLButtonElement,
	IComponentProps &
	React.ButtonHTMLAttributes<HTMLButtonElement> &
	HTMLMotionProps<'button'>
>((props, ref) => {
	const {
		ariaLabel,
		icon,
		iconHeight,
		iconWidth,
		iconClassName,
		children,
		disabled,
	} = props;

	const iconColour = !props.variant ? 'white' : props.iconColour;
	const size = !props.size ? 'md' : props.size;
	const type = !props.type ? 'button' : props.type;
	const variant = !props.variant ? 'primary' : props.variant;

	return (
		<StyledButton
			ref={ref}
			aria-label={ariaLabel}
			variant={variant}
			type={type}
			size={size}
			whileHover={!disabled ? { scale: 1.01 } : ''}
			whileTap={!disabled ? { scale: 0.95 } : ''}
			{...props}
		>
			{icon && (
				<StyledIcon
					className={iconClassName}
					name={icon}
					width={iconWidth}
					height={iconHeight}
					colour={iconColour}
				/>
			)}
			{children}
		</StyledButton>
	);
});

export default Button;
