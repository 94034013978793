import { ReactComponent as age18 } from 'assets/svg/age-18.svg';
import { ReactComponent as alert } from 'assets/svg/alert.svg';
import { ReactComponent as appStore } from 'assets/svg/appStore.svg';
import { ReactComponent as appleLogo } from 'assets/svg/appleLogo.svg';
import { ReactComponent as arrow } from 'assets/svg/arrow.svg';
import { ReactComponent as askForAngela } from 'assets/svg/ask-for-angela.svg';
import { ReactComponent as avatar } from 'assets/svg/avatar.svg';
import { ReactComponent as back } from 'assets/svg/back.svg';
import { ReactComponent as bank } from 'assets/svg/bank.svg';
import { ReactComponent as basket } from 'assets/svg/basket.svg';
import { ReactComponent as basketEmpty } from 'assets/svg/basketEmpty.svg';
import { ReactComponent as bike } from 'assets/svg/bike.svg';
import { ReactComponent as calendar } from 'assets/svg/calendar.svg';
import { ReactComponent as camera } from 'assets/svg/camera.svg';
import { ReactComponent as checkbox } from 'assets/svg/checkbox.svg';
import { ReactComponent as chevron } from 'assets/svg/chevron.svg';
import { ReactComponent as circleCross } from 'assets/svg/circleCross.svg';
import { ReactComponent as clickAndCollect } from 'assets/svg/clickAndCollect.svg';
import { ReactComponent as clock } from 'assets/svg/clock.svg';
import { ReactComponent as clockThick } from 'assets/svg/clockThick.svg';
import { ReactComponent as close } from 'assets/svg/close.svg';
import { ReactComponent as cloud } from 'assets/svg/cloud.svg';
import { ReactComponent as crossRounded } from 'assets/svg/cross-rounded.svg';
import { ReactComponent as cross } from 'assets/svg/cross.svg';
import { ReactComponent as customService } from 'assets/svg/customService.svg';
import { ReactComponent as delivery } from 'assets/svg/delivery.svg';
import { ReactComponent as discount } from 'assets/svg/discount.svg';
import { ReactComponent as discoveryDollars } from 'assets/svg/discoveryDollars.svg';
import { ReactComponent as document } from 'assets/svg/document.svg';
import { ReactComponent as duplicate } from 'assets/svg/duplicate.svg';
import { ReactComponent as edit } from 'assets/svg/edit.svg';
import { ReactComponent as editCard } from 'assets/svg/editCard.svg';
import { ReactComponent as errorLight } from 'assets/svg/error-light.svg';
import { ReactComponent as error } from 'assets/svg/error.svg';
import { ReactComponent as faceAwesome } from 'assets/svg/faceAwesome.svg';
import { ReactComponent as faceBad } from 'assets/svg/faceBad.svg';
import { ReactComponent as faceGood } from 'assets/svg/faceGood.svg';
import { ReactComponent as faceGreat } from 'assets/svg/faceGreat.svg';
import { ReactComponent as faceOk } from 'assets/svg/faceOk.svg';
import { ReactComponent as facebook } from 'assets/svg/facebook.svg';
import { ReactComponent as facebookColour } from 'assets/svg/facebookColour.svg';
import { ReactComponent as feedbackChoice } from 'assets/svg/feedback/choice.svg';
import { ReactComponent as feedbackCleanliness } from 'assets/svg/feedback/cleanliness.svg';
import { ReactComponent as feedbackConvenience } from 'assets/svg/feedback/convenience.svg';
import { ReactComponent as feedbackDetail } from 'assets/svg/feedback/detail.svg';
import { ReactComponent as feedbackFriendliness } from 'assets/svg/feedback/friendliness.svg';
import { ReactComponent as feedbackQuality } from 'assets/svg/feedback/quality.svg';
import { ReactComponent as feedbackSpeed } from 'assets/svg/feedback/speed.svg';
import { ReactComponent as feedbackStarActive } from 'assets/svg/feedback/star-active.svg';
import { ReactComponent as feedbackStar } from 'assets/svg/feedback/star.svg';
import { ReactComponent as feedbackValue } from 'assets/svg/feedback/value.svg';
import { ReactComponent as findTab } from 'assets/svg/findTab.svg';
import { ReactComponent as freshWorks } from 'assets/svg/freshWorks.svg';
import { ReactComponent as fullLogo } from 'assets/svg/fullLogo.svg';
import { ReactComponent as gift } from 'assets/svg/gift.svg';
import { ReactComponent as giftHistory } from 'assets/svg/giftHistory.svg';
import { ReactComponent as giftSent } from 'assets/svg/giftSent.svg';
import { ReactComponent as giftSentSuccess } from 'assets/svg/giftSentSuccess.svg';
import { ReactComponent as giftSentUnclaimed } from 'assets/svg/giftSentUnclaimed.svg';
import { ReactComponent as googleColour } from 'assets/svg/googleColour.svg';
import { ReactComponent as googleLogo } from 'assets/svg/googleLogo.svg';
import { ReactComponent as googlePay } from 'assets/svg/googlePay.svg';
import { ReactComponent as group } from 'assets/svg/group.svg';
import { ReactComponent as groupOrder } from 'assets/svg/groupOrder.svg';
import { ReactComponent as groupOrderAbout } from 'assets/svg/groupOrderAbout.svg';
import { ReactComponent as info } from 'assets/svg/info.svg';
import { ReactComponent as instagram } from 'assets/svg/instagram.svg';
import { ReactComponent as list } from 'assets/svg/list.svg';
import { ReactComponent as location } from 'assets/svg/location.svg';
import { ReactComponent as login } from 'assets/svg/login.svg';
import { ReactComponent as logoBg } from 'assets/svg/logo-bg.svg';
import { ReactComponent as logo } from 'assets/svg/logo.svg';
import { ReactComponent as logout } from 'assets/svg/logout.svg';
import { ReactComponent as map } from 'assets/svg/map.svg';
import { ReactComponent as mapPin } from 'assets/svg/mapPin.svg';
import { ReactComponent as maths } from 'assets/svg/maths.svg';
import { ReactComponent as menu } from 'assets/svg/menu.svg';
import { ReactComponent as minus } from 'assets/svg/minus.svg';
import { ReactComponent as navAllergens } from 'assets/svg/navigation/allergens.svg';
import { ReactComponent as navBell } from 'assets/svg/navigation/bell.svg';
import { ReactComponent as navProfile } from 'assets/svg/navigation/edit-profile.svg';
import { ReactComponent as navGift } from 'assets/svg/navigation/gift.svg';
import { ReactComponent as hamburger } from 'assets/svg/navigation/hamburger.svg';
import { ReactComponent as navLogOut } from 'assets/svg/navigation/log-out.svg';
import { ReactComponent as navOrders } from 'assets/svg/navigation/orders.svg';
import { ReactComponent as navWallet } from 'assets/svg/navigation/wallet.svg';
import { ReactComponent as nfc } from 'assets/svg/nfc.svg';
import { ReactComponent as orderAndPay } from 'assets/svg/orderAndPay.svg';
import { ReactComponent as orderNo } from 'assets/svg/orderNo.svg';
import { ReactComponent as orderSent } from 'assets/svg/orderSent.svg';
import { ReactComponent as payAndGo } from 'assets/svg/payAndGo.svg';
import { ReactComponent as amex } from 'assets/svg/payment/amex.svg';
import { ReactComponent as apple } from 'assets/svg/payment/apple.svg';
import { ReactComponent as generic } from 'assets/svg/payment/generic.svg';
import { ReactComponent as google } from 'assets/svg/payment/google.svg';
import { ReactComponent as maestro } from 'assets/svg/payment/maestro.svg';
import { ReactComponent as mastercard } from 'assets/svg/payment/mastercard.svg';
import { ReactComponent as visa } from 'assets/svg/payment/visa.svg';
import { ReactComponent as percent } from 'assets/svg/percent.svg';
import { ReactComponent as phone } from 'assets/svg/phone.svg';
import { ReactComponent as pin } from 'assets/svg/pin.svg';
import { ReactComponent as playStore } from 'assets/svg/playStore.svg';
import { ReactComponent as plus } from 'assets/svg/plus.svg';
import { ReactComponent as privacy } from 'assets/svg/privacy.svg';
import { ReactComponent as profile } from 'assets/svg/profile.svg';
import { ReactComponent as promoDude } from 'assets/svg/promoDude.svg';
import { ReactComponent as refresh } from 'assets/svg/refresh.svg';
import { ReactComponent as remove } from 'assets/svg/remove.svg';
import { ReactComponent as sameAgain } from 'assets/svg/sameAgain.svg';
import { ReactComponent as search } from 'assets/svg/search.svg';
import { ReactComponent as select } from 'assets/svg/select.svg';
import { ReactComponent as selfService } from 'assets/svg/selfService.svg';
import { ReactComponent as serviceWaitTime } from 'assets/svg/serviceWaitTime.svg';
import { ReactComponent as share } from 'assets/svg/share.svg';
import { ReactComponent as star } from 'assets/svg/star.svg';
import { ReactComponent as starOutline } from 'assets/svg/starOutline.svg';
import { ReactComponent as success } from 'assets/svg/success.svg';
import { ReactComponent as successTick } from 'assets/svg/successTick.svg';
import { ReactComponent as tab } from 'assets/svg/tab.svg';
import { ReactComponent as tabSlim } from 'assets/svg/tabSlim.svg';
import { ReactComponent as table } from 'assets/svg/table.svg';
import { ReactComponent as tableBooking } from 'assets/svg/tableBooking.svg';
import { ReactComponent as tableNo } from 'assets/svg/tableNo.svg';
import { ReactComponent as tableService } from 'assets/svg/tableService.svg';
import { ReactComponent as tableServicePayLaterHero } from 'assets/svg/tableServicePayLaterHero.svg';
import { ReactComponent as telescope } from 'assets/svg/telescope.svg';
import { ReactComponent as termsConditions } from 'assets/svg/termsConditions.svg';
import { ReactComponent as thickAlert } from 'assets/svg/thick-alert.svg';
import { ReactComponent as thickTickCircle } from 'assets/svg/thick-tick-circle.svg';
import { ReactComponent as tickCircle } from 'assets/svg/tick-circle.svg';
import { ReactComponent as tick } from 'assets/svg/tick.svg';
import { ReactComponent as tooltipInfo } from 'assets/svg/tooltipInfo.svg';
import { ReactComponent as twitter } from 'assets/svg/twitter.svg';
import { ReactComponent as voucher } from 'assets/svg/voucher.svg';
import { ReactComponent as wallet } from 'assets/svg/wallet.svg';
import { ReactComponent as warning } from 'assets/svg/warning.svg';
import { ReactComponent as warningFilled } from 'assets/svg/warningFilled.svg';

const iconsConfig = {
	age18,
	alert,
	amex,
	apple,
	appleLogo,
	appStore,
	arrow,
	askForAngela,
	avatar,
	back,
	bank,
	basket,
	basketEmpty,
	bike,
	calendar,
	camera,
	checkbox,
	chevron,
	circleCross,
	clickAndCollect,
	clock,
	clockThick,
	close,
	cloud,
	cross,
	crossRounded,
	customService,
	delivery,
	discount,
	discoveryDollars,
	document,
	duplicate,
	edit,
	editCard,
	error,
	errorLight,
	faceAwesome,
	faceBad,
	faceGood,
	faceGreat,
	faceOk,
	facebook,
	facebookColour,
	feedbackChoice,
	feedbackCleanliness,
	feedbackConvenience,
	feedbackDetail,
	feedbackFriendliness,
	feedbackQuality,
	feedbackSpeed,
	feedbackStar,
	feedbackStarActive,
	feedbackValue,
	findTab,
	freshWorks,
	fullLogo,
	generic,
	gift,
	giftHistory,
	giftSent,
	giftSentSuccess,
	giftSentUnclaimed,
	google,
	googleColour,
	googleLogo,
	googlePay,
	group,
	groupOrder,
	groupOrderAbout,
	hamburger,
	info,
	instagram,
	location,
	list,
	login,
	logo,
	logoBg,
	logout,
	maestro,
	map,
	mapPin,
	mastercard,
	maths,
	menu,
	minus,
	navBell,
	navLogOut,
	navAllergens,
	navProfile,
	navGift,
	navOrders,
	navWallet,
	nfc,
	orderAndPay,
	orderNo,
	orderSent,
	percent,
	phone,
	pin,
	playStore,
	plus,
	privacy,
	promoDude,
	profile,
	refresh,
	remove,
	sameAgain,
	search,
	select,
	selfService,
	share,
	star,
	starOutline,
	success,
	successTick,
	tab,
	table,
	tableBooking,
	tableNo,
	tableService,
	tableServicePayLaterHero,
	tabSlim,
	telescope,
	termsConditions,
	thickAlert,
	thickTickCircle,
	tick,
	tickCircle,
	tooltipInfo,
	twitter,
	visa,
	voucher,
	wallet,
	warning,
	warningFilled,
	serviceWaitTime,
	payAndGo,
};

export type TIcons = keyof typeof iconsConfig;

// Export all icons as default
export default iconsConfig;
