/** Font faces */
const fontFaces = `
	@font-face {
		font-family: 'Montserrat';
		src: local('Montserrat'), local('Montserrat'),
			url(${'/fonts/montserrat-regular-webfont.woff2'}) format('woff2'),
			url(${'/fonts/montserrat-regular-webfont.woff'}) format('woff');
		font-weight: 400;
		font-style: normal;
	}
	@font-face {
		font-family: 'Montserrat';
		src: local('Montserrat'), local('Montserrat'),
			url(${'/fonts/montserrat-light-webfont.woff2'}) format('woff2'),
			url(${'/fonts/montserrat-light-webfont.woff'}) format('woff');
		font-weight: 300;
		font-style: normal;
	}
	@font-face {
		font-family: 'Montserrat';
		src: local('Montserrat'), local('Montserrat'),
			url(${'/fonts/montserrat-lightitalic-webfont.woff2'}) format('woff2'),
			url(${'/fonts/montserrat-lightitalic-webfont.woff'}) format('woff');
		font-weight: 300;
		font-style: italic;
	}
	@font-face {
		font-family: 'Montserrat';
		src: local('Montserrat'), local('Montserrat'),
			url(${'/fonts/montserrat-medium-webfont.woff2'}) format('woff2'),
			url(${'/fonts/montserrat-medium-webfont.woff'}) format('woff');
		font-weight: 500;
		font-style: normal;
	}
	@font-face {
		font-family: 'Montserrat';
		src: local('Montserrat'), local('Montserrat'),
			url(${'/fonts/montserrat-semibold-webfont.woff2'}) format('woff2'),
			url(${'/fonts/montserrat-semibold-webfont.woff'}) format('woff');
		font-weight: 600;
		font-style: normal;
	}
	@font-face {
		font-family: 'Montserrat';
		src: local('Montserrat'), local('Montserrat'),
			url(${'/fonts/montserrat-bold-webfont.woff2'}) format('woff2'),
			url(${'/fonts/montserrat-bold-webfont.woff'}) format('woff');
		font-weight: 700;
		font-style: normal;
	}
	@font-face {
		font-family: 'IvyMode';
		src: local('IvyMode'), local('IvyMode'),
			url(${'/fonts/IvyMode-GHA-Regular.otf'});
		font-weight: 400;
		font-style: normal;
	}
	@font-face {
		font-family: 'IvyMode';
		src: local('IvyMode'), local('IvyMode'),
			url(${'/fonts/IvyMode-GHA-Semi-Bold.otf'});
		font-weight: 600;
		font-style: normal;
	}
	@font-face {
		font-family: 'IvyMode';
		src: local('IvyMode'), local('IvyMode'),
			url(${'/fonts/IvyMode-GHA-Light.otf'});
		font-weight: 300;
		font-style: normal;
	}
	@font-face {
		font-family: 'IvyMode';
		src: local('IvyMode'), local('IvyMode'),
			url(${'/fonts/IvyMode-GHA-Light-Italic.otf'});
		font-weight: 300;
		font-style: italic;
	}
	@font-face {
		font-family: 'IvyMode';
		src: local('IvyMode'), local('IvyMode'),
			url(${'/fonts/IvyMode-GHA-Semi-Bold-Italic.otf'});
		font-weight: 600;
		font-style: italic;
	}
	@font-face {
		font-family: 'IvyMode';
		src: local('IvyMode'), local('IvyMode'),
			url(${'/fonts/IvyMode-GHA-Italic.otf'});
		font-weight: 400;
		font-style: italic;
	}
	@font-face {
		font-family: 'Jost';
		src: local('Jost'), local('Jost'),
			url(${'/fonts/Jost-VariableFont_wght.ttf'});
		font-weight: 100 900;
		font-style: normal;
		font-display: swap;
	}
	@font-face {
		font-family: 'Jost';
		src: local('Jost'), local('Jost'),
			url(${'/fonts/Jost-Italic-VariableFont_wght.ttf'});
		font-weight: 100 900;
		font-style: italic;
		font-display: swap;
	}
`;

export default fontFaces;
