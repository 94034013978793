import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IGHAStatusResponse, IGHABalanceResponse, IGHAErrorResponse, IGHALoginValues, IGHASignupResponse, IGHASignupValues } from './gha.types';

import { ITip } from 'modules/basket/basket.types';

export interface IGhaState {
	loggingIn: boolean;
	eventsInProgress: number;
	name: string;
	spend: number;
	spendInCurrency: number;
	schemeId?: string;
	hasShownPopup: boolean;
	orderId?: string;
	status?: IGHAStatusResponse;
	recentPaymentId?: string;
	splitAndTip?: {
		split?: number;
		tip?: ITip;
		orderId?: string;
	};
	enrollmentCode?: string;
}

export const initialGhaState: IGhaState = {
	loggingIn: false,
	eventsInProgress: 0,
	name: '',
	spend: 0,
	spendInCurrency: 0,
	schemeId: undefined,
	hasShownPopup: false,
	orderId: undefined,
	status: undefined,
	recentPaymentId: undefined,
	enrollmentCode: undefined,
};

const ghaSlice = createSlice({
	name: 'gha',
	initialState: initialGhaState,
	reducers: {
		GHA_LOGIN(state, action) {
			return {
				...state,
				loggingIn: true,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GHA_LOGIN_SUCCESS(state, action) {
			return {
				...state,
				loggingIn: false,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GHA_LOGIN_FAIL(state, action) {
			return {
				...state,
				ghaLoggingIn: false,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GHA_LOGOUT(state, action) {
			return {
				...state,
				loggingIn: false,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GHA_LOGOUT_SUCCESS(state, action) {
			return {
				...initialGhaState,
			};
		},
		GHA_LOGOUT_FAIL(state, action) {
			return {
				...state,
				loggingIn: false,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GHA_SIGNUP(state, action) {
			return {
				...state,
				loggingIn: true,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GHA_SIGNUP_SUCCESS(state, action) {
			return {
				...state,
				loggingIn: false,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GHA_SIGNUP_FAIL(state, action) {
			return {
				...state,
				loggingIn: false,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GHA_SET_NAME(state, action) {
			return {
				...state,
				name: action.payload,
			};
		},
		GHA_BALANCE(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GHA_BALANCE_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				balance: action.payload,
			};
		},
		GHA_BALANCE_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GHA_BALANCE_WITH_SPEND(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GHA_BALANCE_WITH_SPEND_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				balance: action.payload,
			};
		},
		GHA_BALANCE_WITH_SPEND_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GHA_SPEND(state, action) {
			return {
				...state,
				spend: action.payload.spend,
				spendInCurrency: action.payload.spendInCurrency,
				orderId: action.payload.orderId,
				hasShownPopup: false,
			};
		},
		GHA_SET_SCHEME_ID(state, action) {
			return {
				...state,
				schemeId: action.payload,
			};
		},
		GHA_HAS_SHOWN_POPUP(state, action) {
			return {
				...state,
				hasShownPopup: action.payload,
			};
		},
		GHA_SET_SPLIT_AND_TIP(state, action) {
			return {
				...state,
				splitAndTip: action.payload,
			};
		},
		GHA_STATUS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GHA_STATUS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				status: action.payload,
			};
		},
		GHA_STATUS_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GHA_SET_RECENT_PAYMENT_ID(state, action) {
			return {
				...state,
				recentPaymentId: action.payload,
			};
		},
		GHA_SET_ENROLLMENT_CODE(state, action) {
			return {
				...state,
				enrollmentCode: action.payload,
			};
		},
	},
});

export const {
	GHA_LOGIN,
	GHA_LOGIN_SUCCESS,
	GHA_LOGIN_FAIL,
	GHA_SIGNUP,
	GHA_SIGNUP_SUCCESS,
	GHA_SIGNUP_FAIL,
	GHA_SET_NAME,
	GHA_BALANCE,
	GHA_BALANCE_SUCCESS,
	GHA_BALANCE_FAIL,
	GHA_BALANCE_WITH_SPEND,
	GHA_BALANCE_WITH_SPEND_SUCCESS,
	GHA_BALANCE_WITH_SPEND_FAIL,
	GHA_SPEND,
	GHA_SET_SCHEME_ID,
	GHA_HAS_SHOWN_POPUP,
	GHA_LOGOUT,
	GHA_LOGOUT_SUCCESS,
	GHA_LOGOUT_FAIL,
	GHA_SET_SPLIT_AND_TIP,
	GHA_STATUS,
	GHA_STATUS_SUCCESS,
	GHA_STATUS_FAIL,
	GHA_SET_RECENT_PAYMENT_ID,
	GHA_SET_ENROLLMENT_CODE,
} = ghaSlice.actions;

/**
 * Submits a basket with upsell items
 */
export const submitGhaLogin = (values: IGHALoginValues, schemeId: string) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		GHA_LOGIN({
			request: {
				method: 'POST',
				url: `1/loyalty/${schemeId}/login`,
				data: values,
			},
		}),
	) as {
		payload: {
			status: number;
			data: IGHAErrorResponse | IGHASignupResponse;
		};
		type: string;
		error?: {
			response?: {
				data?: IGHAErrorResponse;
			};
		};
	};

	if (response && 'error' in response) {
		const typedError = response?.error as { response?: { data?: IGHAErrorResponse | string } };
		if (typedError?.response?.data) {
			if (typeof typedError?.response?.data === 'string') {
				return {
					error: 'Unknown',
					message: typedError?.response?.data,
				};
			}
			return typedError?.response?.data;
		}
	}

	return response?.payload?.status === 201 ? response?.payload?.data : false;
};

export const submitGhaLogout = (schemeId: string) => async (dispatch: Dispatch) => {
	await dispatch(GHA_LOGOUT({
		request: {
			method: 'POST',
			url: `1/loyalty/${schemeId}/logout`,
		},
	}));
};

/**
 * Submits a basket with upsell items
 */
export const submitGhaSignup = (values: IGHASignupValues, schemeId: string) => async (
	dispatch: Dispatch,
): Promise<IGHASignupResponse | IGHAErrorResponse | false> => {
	const response = await dispatch(
		GHA_SIGNUP({
			request: {
				method: 'POST',
				url: `1/loyalty/${schemeId}/register`,
				data: values,
			},
		}),
	) as {
		payload: {
			status: number;
			data: IGHAErrorResponse | IGHASignupResponse;
		};
		type: string;
		error?: {
			response?: {
				data?: IGHAErrorResponse;
			};
		};
	};

	if (response && 'error' in response) {
		const typedError = response?.error as { response?: { data?: IGHAErrorResponse } };
		if (typedError?.response?.data) {
			return typedError?.response?.data;
		}
	}

	return response?.payload?.status === 201 ? response?.payload?.data : false;
};

export const getGhaBalance = (schemeId: string, orderId: string, split?: string) =>
	async (dispatch: Dispatch): Promise<IGHABalanceResponse | IGHAErrorResponse | false> => {
		const response = await dispatch(
			GHA_BALANCE({
				request: {
					method: 'GET',
					url: `1/loyalty/${schemeId}/balance/${orderId}${split ? `?payment=${split}` : ''}`,
				},
			}),
		) as {
			payload: {
				status: number;
				data: IGHAErrorResponse | IGHABalanceResponse;
			};
			type: string;
			error?: {
				response?: {
					data?: IGHAErrorResponse;
				};
			};
		};

		if (response && 'error' in response) {
			const typedError = response?.error as { response?: { data?: IGHAErrorResponse } };
			if (typedError?.response?.data) {
				return typedError?.response?.data;
			}
		}

		return response?.payload?.status === 200 ? response?.payload?.data : false;
	};

export const getGhaBalanceWithSpend = (schemeId: string, orderId: string, spend: number, split?: string) =>
	async (dispatch: Dispatch): Promise<IGHABalanceResponse | IGHAErrorResponse | false> => {
		const response = await dispatch(
			GHA_BALANCE_WITH_SPEND({
				request: {
					method: 'GET',
					url: `1/loyalty/${schemeId}/balance/${orderId}?redeem=${spend}${split ? `&payment=${split}` : ''}`,
				},
			}),
		) as {
			payload: {
				status: number;
				data: IGHAErrorResponse | IGHABalanceResponse;
			};
			type: string;
			error?: {
				response?: {
					data?: IGHAErrorResponse;
				};
			};
		};

		if (response && 'error' in response) {
			const typedError = response?.error as { response?: { data?: IGHAErrorResponse } };
			if (typedError?.response?.data) {
				return typedError?.response?.data;
			}
		}

		return response?.payload?.status === 200 ? response?.payload?.data : false;
	};


export const setGhaName = (name: string) => async (dispatch: Dispatch) => {
	dispatch(GHA_SET_NAME(name));
};

export const setGhaSchemeId = (schemeId: string) => async (dispatch: Dispatch) => {
	dispatch(GHA_SET_SCHEME_ID(schemeId));
};

export const setGhaSpend =
	(spend: number, spendInCurrency: number, orderId: string) => async (dispatch: Dispatch) => {
		dispatch(GHA_SPEND({ spend, spendInCurrency, orderId }));
	};

export const setHasShownPopup = (hasShownPopup: boolean) => async (dispatch: Dispatch) => {
	dispatch(GHA_HAS_SHOWN_POPUP(hasShownPopup));
};

export const setSplitAndTip = (split?: number, tip?: ITip, orderId?: string) => async (dispatch: Dispatch) => {
	dispatch(GHA_SET_SPLIT_AND_TIP({ split, tip, orderId }));
};

export const setGhaRecentPaymentId = (paymentId?: string) => async (dispatch: Dispatch) => {
	dispatch(GHA_SET_RECENT_PAYMENT_ID(paymentId));
};

export const resetSpendingState = (resetSchemeId?: boolean) => async (dispatch: Dispatch) => {
	dispatch(GHA_SPEND({ spend: 0, spendInCurrency: 0, orderId: undefined }));
	dispatch(GHA_SET_SPLIT_AND_TIP({ split: undefined, tip: undefined, orderId: undefined }));
	if (resetSchemeId) {
		dispatch(GHA_SET_SCHEME_ID(undefined));
	}
};

export const getGhaStatus = (paymentId: string) =>
	async (dispatch: Dispatch): Promise<IGHAStatusResponse | IGHAErrorResponse | false> => {
		const response = await dispatch(
			GHA_STATUS({
				request: {
					method: 'GET',
					url: `1/loyalty/status/${paymentId}`,
				},
			}),
		) as {
			payload: {
				status: number;
				data: IGHAErrorResponse | IGHAStatusResponse;
			};
			type: string;
			error?: {
				response?: {
					data?: IGHAErrorResponse;
				};
			};
		};

		if (response && 'error' in response) {
			const typedError = response?.error as { response?: { data?: IGHAErrorResponse } };
			if (typedError?.response?.data) {
				return typedError?.response?.data;
			}
		}

		return response?.payload?.status === 200 ? response?.payload?.data : false;
	};

export const setGhaEnrollmentCode = (enrollmentCode?: string) => async (dispatch: Dispatch) => {
	dispatch(GHA_SET_ENROLLMENT_CODE(enrollmentCode));
};

export default ghaSlice.reducer;
