import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { CancelTokenSource } from 'axios';
import * as uuid from 'uuid';

import {
	EReferralSource,
	IBasket,
	IBasketDiscountCard,
	IBasketReferral,
	IBasketState,
	IBasketToSubmit,
	IOrder,
	IOrderItem,
	IReward,
	IServiceCharge,
	ITip,
	IVoucher,
	TCheckInPage,
	TOrderStatus,
	TServiceType,
} from './basket.types';

import calculateAmountToPay from 'modules/payment/helpers/amount-to-pay.helper';
import calculateTipAmount from 'modules/payment/helpers/calculate-tip-amount.helper';
import {
	ETableServiceFeature,
	ITimeslot,
	IVenue,
} from 'modules/venue/venue.types';

// Create initial state
export const initialBasketState: IBasketState = {
	eventsInProgress: 0,
	items: [],
	activeBasket: {
		id: '',
		isValidated: false,
		splitBillAmount: 0,
	},
	shouldProcessBasket: true,
	isBasketValidating: false,
	isTimeSlotPickerVisible: false,
	isTableNumberPickerVisible: false,
	isCheckInVisible: false,
	isCardRequiredPromptVisible: false,
	isAgeRestrictedVisible: false,
	isOrderNoteSheetVisible: false,
	isItemNoteSheetVisible: false,
	activeCheckInPage: 'tableNumber',
	invalidateBasketHash: false,
	orderingAgain: false,
	pagination: {
		pageSize: 20,
		pageNumber: 1,
		pageCount: 1,
	},
	fetchingBasket: false,
	posOrderId: undefined,
};

const basketSlice = createSlice({
	name: 'basket',
	initialState: initialBasketState,
	reducers: {
		SET_BASKET_VENUE(state, action) {
			return {
				...state,
				venue: action.payload,
				activeBasket: {
					...state.activeBasket,
					venueId: action.payload.id,
				},
			};
		},
		ADD_BASKET_ITEM(state, action) {
			return {
				...state,
				items: [...state.items, action.payload],
				activeBasket: {
					...state.activeBasket,
					isValidated: false,
				},
			};
		},
		ADD_BASKET_ITEMS(state, action) {
			return {
				...state,
				items: [...state.items, ...action.payload],
			};
		},
		UPDATE_BASKET_ITEM(state, action) {
			return {
				...state,
				items: state.items.map((item) =>
					item.clientId === action.payload.clientId
						? { ...item, ...action.payload }
						: item,
				),
				activeBasket: {
					...state.activeBasket,
					isValidated: false,
				},
			};
		},
		DUPLICATE_BASKET_ITEM(state, action) {
			// Copy items from state
			const items = [...state.items];

			// Find the payload item's index in the array
			const payloadIndex = state.items.findIndex(
				(item) => item.clientId === action.payload.clientId,
			);

			// Add a copy of this item after the payload item
			items.splice(payloadIndex + 1, 0, {
				...action.payload,
				clientId: uuid.v4(),
			});

			return {
				...state,
				items,
				activeBasket: {
					...state.activeBasket,
					isValidated: false,
				},
			};
		},
		REMOVE_BASKET_ITEM(state, action) {
			const { clientId, productId } = action.payload;

			// If we have a client id, we know exactly which item to remove
			if (clientId) {
				return {
					...state,
					items: state.items.filter((item) => {
						return item.clientId !== clientId;
					}),
					activeBasket: {
						...state.activeBasket,
						isValidated: false,
					},
				};
			}

			// If we just have a productId then we need to find a single item to remove from the basket
			let changedItem = false;
			const items = [...state.items]
				// reverse the list to remove the last added item
				.reverse()
				// reduce the quantity of the first item that matches
				.map((item) => {
					// Short circuit if we've already changed an item
					if (changedItem || item.productId !== productId) {
						return { ...item };
					}
					// Track that we've changed an item
					changedItem = true;
					return {
						...item,
						quantity: item.quantity - 1,
					};
				})
				// Remove the item that has a zero quantity
				.filter((item) => item.quantity > 0)
				// Put back to original order
				.reverse();

			return {
				...state,
				items,
				activeBasket: {
					...state.activeBasket,
					isValidated: false,
				},
			};
		},
		RESET_BASKET_STATE() {
			return { ...initialBasketState };
		},
		CREATE_BASKET(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
				isBasketValidating: true,
				activeBasket: {
					...state.activeBasket,
					status: undefined,
				},
			};
		},
		CREATE_BASKET_SUCCESS(state, action) {
			// TODO - This is temporary until basket hashing is fixed
			let hash = action.payload?.data?.hash;
			if (state.invalidateBasketHash) {
				hash = '';
			}

			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					id: action.payload?.data?.id,
					isValidated: true,
					expiry: action.payload?.data?.expiry,
					serviceType: action.payload?.data?.serviceType,
					status: action.payload?.data?.status,
					timeSlot: action.payload?.data?.timeSlot,
					venueId: state.venue?.id,
					hash,
				},
				shouldProcessBasket: false,
				eventsInProgress: state.eventsInProgress - 1,
				// TODO - This is temporary until basket hashing is fixed
				invalidateBasketHash: false,
			};
		},
		CREATE_BASKET_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeBasket: {
					...state.activeBasket,
					isValidated: false,
				},
				shouldProcessBasket: true,
				// TODO - This is temporary until basket hashing is fixed
				invalidateBasketHash: false,
			};
		},
		UPDATE_BASKET(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
				isBasketValidating: true,
				activeBasket: {
					...state.activeBasket,
					status: undefined,
				},
			};
		},
		UPDATE_BASKET_SUCCESS(state, action) {
			// TODO - This is temporary until basket hashing is fixed
			let hash = action.payload?.data?.hash;
			if (state.invalidateBasketHash) {
				hash = '';
			}

			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					id: action.payload?.data?.id,
					isValidated: true,
					expiry: action.payload?.data?.expiry,
					serviceType: action.payload?.data?.serviceType,
					status: action.payload?.data?.status,
					timeSlot: action.payload?.data?.timeSlot,
					venueId: state.venue?.id,
					hash,
				},
				shouldProcessBasket: false,
				eventsInProgress: state.eventsInProgress - 1,
				// TODO - This is temporary until basket hashing is fixed
				invalidateBasketHash: false,
			};
		},
		UPDATE_BASKET_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeBasket: {
					...state.activeBasket,
					isValidated: false,
				},
				shouldProcessBasket: true,
				// TODO - This is temporary until basket hashing is fixed
				invalidateBasketHash: false,
			};
		},
		UPDATE_BASKET_STATUS(state, action) {
			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					status: action.payload,
				},
			};
		},
		SET_SHOULD_PROCESS_BASKET(state, action) {
			return {
				...state,
				shouldProcessBasket: action.payload,
			};
		},
		SET_BASKET_VALIDATING(state, action) {
			return {
				...state,
				isBasketValidating: action.payload,
			};
		},
		GET_BASKET(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_BASKET_SUCCESS(state, action) {
			return {
				...state,
				fullBasket: action.payload?.data,
				eventsInProgress: state.eventsInProgress - 1,
				isBasketValidating: false,
				activeBasket: {
					...state.activeBasket,
					tableNumber:
						action.payload?.data.tableNumber || state.activeBasket.tableNumber,
					lastFetch: new Date(),
				},
			};
		},
		GET_BASKET_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				shouldProcessBasket: true,
				isBasketValidating: false,
			};
		},
		SET_TIMESLOT_PICKER_VISIBILITY(state, action) {
			return {
				...state,
				isTimeSlotPickerVisible: action.payload,
			};
		},
		SET_TABLE_NUMBER_PICKER_VISIBILITY(state, action) {
			return {
				...state,
				isTableNumberPickerVisible: action.payload,
			};
		},
		SET_CHECK_IN_VISIBILITY(state, action) {
			return {
				...state,
				isCheckInVisible: action.payload,
			};
		},
		SET_ACTIVE_COVER_COUNT(state, action) {
			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					coverCount: action.payload,
					isValidated: false,
				},
			};
		},
		SET_ACTIVE_TIMESLOT(state, action) {
			return {
				...state,
				activeTimeSlot: action.payload,
				activeBasket: {
					...state.activeBasket,
					timeSlot: action.payload.collectionTime,
					isValidated: false,
				},
			};
		},
		SET_ACTIVE_TABLE_NUMBER(state, action) {
			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					tableNumber: action.payload,
					isValidated: false,
				},
			};
		},
		SET_ACTIVE_SERVICETYPE(state, action) {
			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					serviceType: action.payload,
					isValidated: false,
				},
			};
		},
		SET_ACTIVE_PAYMENT_OPTION(state, action) {
			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					paymentOption: action.payload,
					isValidated: false,
				},
			};
		},
		SET_SERVICECHARGE(state, action) {
			// TODO - This is temporary until basket hashing is fixed
			const serviceCharge = action.payload;
			if (!serviceCharge.amount || !serviceCharge.amount.units) {
				return {
					...state,
					activeBasket: {
						...state.activeBasket,
						serviceCharge: action.payload,
						isValidated: false,
					},
					activeHash: '',
					invalidateBasketHash: true,
				};
			}

			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					serviceCharge: action.payload,
					isValidated: false,
				},
			};
		},
		SET_TIP_AMOUNT(state, action) {
			// TODO - This is temporary until basket hashing is fixed
			const tip = action.payload;
			if (!tip.amount && !tip.percent) {
				return {
					...state,
					activeBasket: {
						...state.activeBasket,
						tipAmount: action.payload,
					},
					activeHash: '',
					invalidateBasketHash: true,
				};
			}

			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					tipAmount: action.payload,
				},
			};
		},
		SET_SPLIT_BILL_AMOUNT(state, action) {
			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					splitBillAmount: action.payload,
				},
			};
		},
		SET_BASKET_GIFTS(state, action) {
			const gifts = action.payload;
			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					gifts,
				},
				activeHash: '',
				invalidateBasketHash: true,
			};
		},
		SET_BASKET_REWARDS(state, action) {
			const rewards = action.payload;
			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					rewards: [rewards],
				},
				activeHash: '',
				invalidateBasketHash: true,
			};
		},
		SET_BASKET_REQUIRES_VALIDATION(state) {
			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					isValidated: false,
				},
			};
		},
		CLEAR_ONPL_BASKET(state) {
			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					isValidated: false,
					expiry: undefined,
					status: undefined,
					customerNotes: undefined,
				},
				items: [],
			};
		},
		CLEAR_BASKET_ORDERS(state) {
			return {
				...state,
				fullBasket: state.fullBasket
					? {
						...state.fullBasket,
						order: undefined,
					  }
					: undefined,
			};
		},
		GET_ACTIVE_BASKET(state, action) {
			return {
				...state,
			};
		},
		GET_ACTIVE_BASKET_SUCCESS(state, action) {
			return {
				...state,
				fullBasket: action.payload?.data,
				activeBasket: {
					...state.activeBasket,
					tableNumber:
						action.payload?.data.tableNumber || state.activeBasket.tableNumber,
					lastFetch: new Date(),
				},
			};
		},
		GET_ACTIVE_BASKET_FAIL(state) {
			return {
				...state,
			};
		},
		ACTIVE_BASKET_FROM_FULL_BASKET(state) {
			return {
				...initialBasketState,
				activeBasket: {
					...initialBasketState.activeBasket,
					id: state.fullBasket?.id || '',
					expiry: state.fullBasket?.expiry,
					serviceType: state.fullBasket?.serviceType,
					status: state.fullBasket?.status,
					timeSlot: undefined,
					tableNumber: state.fullBasket?.tableNumber,
					venueId: state.fullBasket?.venueId,
					posId: state.fullBasket?.posId,
					paymentSummary: state.fullBasket?.paymentSummary,
					coverCount: state.fullBasket?.coverCount,
				},
				fullBasket: state.fullBasket,
			};
		},
		SET_ACTIVE_HASH(state, action) {
			return {
				...state,
				activeHash: action.payload,
			};
		},
		SET_ACTIVE_TAB_NUMBER(state, action) {
			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					tabNumber: action.payload,
				},
			};
		},
		SET_ACTIVE_CHECK_IN_PAGE(state, action) {
			return {
				...state,
				activeCheckInPage: action.payload,
			};
		},
		SET_ACTIVE_BASKET_VALIDATED(state, action) {
			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					isValidated: action.payload,
				},
			};
		},
		SET_HAS_BASKET_UPDATED(state, action) {
			return {
				...state,
				hasBasketUpdated: action.payload,
			};
		},
		SET_CARD_REQUIRED_PROMPT_VISIBILITY(state, action) {
			return {
				...state,
				isCardRequiredPromptVisible: Boolean(action.payload),
			};
		},
		SET_ORDERING_AGAIN(state, action) {
			return {
				...state,
				orderingAgain: Boolean(action.payload),
			};
		},
		CLEAR_BASKET_ITEMS(state) {
			return {
				...state,
				items: [],
			};
		},
		SET_ORDER_NOTE_SHEET_VISIBILITY(state, action) {
			return {
				...state,
				isOrderNoteSheetVisible: action.payload,
			};
		},
		SET_ORDER_NOTE(state, action) {
			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					customerNotes: action.payload,
				},
			};
		},
		SET_POS_ORDER_ID(state, action) {
			return {
				...state,
				posOrderId: action.payload,
			};
		},
		SET_ITEM_NOTE_SHEET_VISIBILITY(state, action) {
			return {
				...state,
				isItemNoteSheetVisible: action.payload,
			};
		},
		SET_ITEM_NOTE(state, action) {
			return handleSetItemNote(state, action);
		},
		SET_FETCHING_BASKET(state, action) {
			return {
				...state,
				fetchingBasket: action.payload,
			};
		},
		SET_AGE_RESTRICTED_VISIBLE(state, action) {
			return {
				...state,
				isAgeRestrictedVisible: action.payload,
			};
		},
		SET_BASKET_VOUCHERS(state, action) {
			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					vouchers: action.payload,
				},
			};
		},
		SET_BASKET_DISCOUNT(state, action) {
			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					discountCard: action.payload,
				},
			};
		},
		SET_BASKET_REFERER(state, action) {
			return {
				...state,
				activeBasket: {
					...state.activeBasket,
					referral: action.payload,
				},
			};
		},
		SET_FULL_BASKET_ORDER(state, action) {
			return {
				...state,
				fullBasket: state.fullBasket
					? {
						...state.fullBasket,
						order: action.payload,
					  }
					: undefined,
			};
		},
		POLL_BASKET(state, action) {
			// Intentionally blank, so as to make this request in the background
			return state;
		},
		POLL_BASKET_SUCCESS(state, action) {
			// Intentionally blank, so as to make this request in the background
			return state;
		},
		POLL_BASKET_FAIL(state, action) {
			// Intentionally blank, so as to make this request in the background
			return state;
		},
		POST_DISCOUNT_CARD(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		POST_DISCOUNT_CARD_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				fullBasket: action.payload.data?.basket ?? state.fullBasket,
			};
		},
		POST_DISCOUNT_CARD_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	SET_BASKET_VENUE,
	ADD_BASKET_ITEM,
	ADD_BASKET_ITEMS,
	UPDATE_BASKET_ITEM,
	DUPLICATE_BASKET_ITEM,
	REMOVE_BASKET_ITEM,
	RESET_BASKET_STATE,
	CREATE_BASKET,
	CREATE_BASKET_SUCCESS,
	CREATE_BASKET_FAIL,
	UPDATE_BASKET,
	UPDATE_BASKET_SUCCESS,
	UPDATE_BASKET_FAIL,
	UPDATE_BASKET_STATUS,
	SET_SHOULD_PROCESS_BASKET,
	SET_BASKET_VALIDATING,
	GET_BASKET,
	GET_BASKET_SUCCESS,
	GET_BASKET_FAIL,
	SET_TIMESLOT_PICKER_VISIBILITY,
	SET_TABLE_NUMBER_PICKER_VISIBILITY,
	SET_CHECK_IN_VISIBILITY,
	SET_ACTIVE_COVER_COUNT,
	SET_ACTIVE_TIMESLOT,
	SET_ACTIVE_TABLE_NUMBER,
	SET_ACTIVE_SERVICETYPE,
	SET_ACTIVE_PAYMENT_OPTION,
	SET_SERVICECHARGE,
	SET_TIP_AMOUNT,
	SET_SPLIT_BILL_AMOUNT,
	SET_BASKET_GIFTS,
	SET_BASKET_REWARDS,
	SET_BASKET_REQUIRES_VALIDATION,
	CLEAR_ONPL_BASKET,
	CLEAR_BASKET_ORDERS,
	GET_ACTIVE_BASKET,
	GET_ACTIVE_BASKET_SUCCESS,
	GET_ACTIVE_BASKET_FAIL,
	ACTIVE_BASKET_FROM_FULL_BASKET,
	SET_ACTIVE_HASH,
	SET_ACTIVE_TAB_NUMBER,
	SET_ACTIVE_CHECK_IN_PAGE,
	SET_ACTIVE_BASKET_VALIDATED,
	SET_HAS_BASKET_UPDATED,
	SET_CARD_REQUIRED_PROMPT_VISIBILITY,
	SET_ORDERING_AGAIN,
	CLEAR_BASKET_ITEMS,
	SET_ORDER_NOTE_SHEET_VISIBILITY,
	SET_ORDER_NOTE,
	SET_POS_ORDER_ID,
	SET_ITEM_NOTE_SHEET_VISIBILITY,
	SET_ITEM_NOTE,
	SET_FETCHING_BASKET,
	SET_AGE_RESTRICTED_VISIBLE,
	SET_BASKET_VOUCHERS,
	SET_BASKET_DISCOUNT,
	SET_BASKET_REFERER,
	SET_FULL_BASKET_ORDER,
	POLL_BASKET,
	POLL_BASKET_SUCCESS,
	POLL_BASKET_FAIL,
	POST_DISCOUNT_CARD,
	POST_DISCOUNT_CARD_SUCCESS,
	POST_DISCOUNT_CARD_FAIL,
} = basketSlice.actions;

/** Reset state */
export const resetBasketState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_BASKET_STATE());
};

/** Set active venue in basket state */
export const setBasketVenue = (venue: IVenue) => async (dispatch: Dispatch) => {
	await dispatch(SET_BASKET_VENUE(venue));
};

/** Add item to basket */
export const addBasketItem = (item: IOrderItem) => async (
	dispatch: Dispatch,
) => {
	await dispatch(ADD_BASKET_ITEM({ ...item, clientId: uuid.v4() }));
};

/** Add multiple items to basket */
export const addBasketItems = (items: IOrderItem[]) => async (
	dispatch: Dispatch,
) => {
	await dispatch(
		ADD_BASKET_ITEMS(items.map((item) => ({ ...item, clientId: uuid.v4() }))),
	);
};

/** Update item in basket */
export const updateBasketItem = (item: IOrderItem) => async (
	dispatch: Dispatch,
) => {
	await dispatch(UPDATE_BASKET_ITEM(item));
};

/** Duplicate item in basket */
export const duplicateBasketItem = (item: IOrderItem) => async (
	dispatch: Dispatch,
) => {
	await dispatch(DUPLICATE_BASKET_ITEM(item));
};

/** Remove item from basket */
export const removeBasketItem = (item: IOrderItem) => async (
	dispatch: Dispatch,
) => {
	await dispatch(REMOVE_BASKET_ITEM(item));
};

/** Create basket on API */
export const createBasket = (order: IBasketToSubmit) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		CREATE_BASKET({
			request: {
				method: 'post',
				url: '1/basket',
				data: order,
				timeout: process.env.REACT_APP_POS_TIMEOUT,
			},
		}),
	);

	return response?.payload;
};

/** Update basket on API */
export const updateBasket = (order: IBasketToSubmit) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		UPDATE_BASKET({
			request: {
				method: 'put',
				url: `1/basket/${order.id}`,
				data: order,
				timeout: process.env.REACT_APP_POS_TIMEOUT,
			},
		}),
	);

	return response?.payload;
};

/** Update active basket status */
export const updateBasketStatus = (status: TOrderStatus) => async (
	dispatch: Dispatch,
) => {
	await dispatch(UPDATE_BASKET_STATUS(status));
};

/** Update whether the current basket should be processed */
export const setShouldProcessBasket = (shouldProcess: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_SHOULD_PROCESS_BASKET(shouldProcess));
};

/** Set basket validating status */
export const setBasketValidating = (validating: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_BASKET_VALIDATING(validating));
};

/** Get basket on API */
export const getBasket = (basketId: string) => async (dispatch: Dispatch) => {
	const response = await dispatch(
		GET_BASKET({
			request: {
				method: 'get',
				url: `2/basket/${basketId}`,
				timeout: process.env.REACT_APP_POS_TIMEOUT,
			},
		}),
	);

	return response.payload?.data;
};

/** Show/hide time slot picker */
export const setTimeSlotPickerVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_TIMESLOT_PICKER_VISIBILITY(visible));
};

/** Show/hide table number picker */
export const setTableNumberPickerVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_TABLE_NUMBER_PICKER_VISIBILITY(visible));
};

/** Show/hide ONPL check in */
export const setCheckInVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_CHECK_IN_VISIBILITY(visible));
};

/** set active cover count */
export const setActiveCoverCount = (coverCount: number) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_ACTIVE_COVER_COUNT(coverCount));
};

/** Set active time slot for order */
export const setActiveTimeslot = (timeSlot: ITimeslot) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_ACTIVE_TIMESLOT(timeSlot));
};

/** Set active table number for order */
export const setActiveTableNumber = (tableNumber: string) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_ACTIVE_TABLE_NUMBER(tableNumber));
};

/** Set active service type for order */
export const setActiveServiceType = (serviceType: TServiceType) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_ACTIVE_SERVICETYPE(serviceType));
};

/** Set active payment option for order */
export const setActivePaymentOption = (
	paymentType?: keyof typeof ETableServiceFeature,
) => async (dispatch: Dispatch) => {
	await dispatch(SET_ACTIVE_PAYMENT_OPTION(paymentType));
};

/** Set service charge value for order */
export const setServiceCharge = (serviceCharge: IServiceCharge) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_SERVICECHARGE(serviceCharge));
};

/** Set tip amount for order */
export const setTipAmount = (tipAmount: ITip) => async (dispatch: Dispatch) => {
	await dispatch(SET_TIP_AMOUNT(tipAmount));
};

/** Set split bill amount for order */
export const setSplitBillAmount = (amount: number) => async (
	dispatch: Dispatch,
	getState: () => { basket: IBasketState },
) => {
	await dispatch(SET_SPLIT_BILL_AMOUNT(amount));

	const { basket } = getState();

	if (
		basket.activeBasket.tipAmount?.percent &&
		basket.activeBasket.tipAmount.amount?.units &&
		basket.fullBasket
	) {
		const tipAmount = calculateTipAmount({
			tipAmount: basket.activeBasket.tipAmount,
			subTotal:
				amount ||
				calculateAmountToPay(basket.activeBasket, basket.fullBasket!, false),
		});
		const tip: ITip = {
			amount: {
				...basket.activeBasket.tipAmount.amount,
				units: tipAmount,
			},
			percent: basket.activeBasket.tipAmount.percent,
		};

		await dispatch(SET_TIP_AMOUNT(tip));
	}
};

/** Set basket gifts */
export const setBasketGifts = (gifts: string[]) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_BASKET_GIFTS(gifts));
};

/** Set basket reward */
export const setBasketRewards = (rewards: IReward) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_BASKET_REWARDS(rewards));
};

/** Set basket as requires validation */
export const setBasketRequiresValidation = () => async (dispatch: Dispatch) => {
	await dispatch(SET_BASKET_REQUIRES_VALIDATION());
};

/** Remove basket items and reset some areas of active basket */
export const clearOnplBasket = () => async (dispatch: Dispatch) => {
	await dispatch(CLEAR_ONPL_BASKET());
};

/** Get basket on API */
export const getActiveBasket = () => async (dispatch: Dispatch) => {
	const response = await dispatch(
		GET_ACTIVE_BASKET({
			request: {
				method: 'get',
				url: '2/basket/active',
			},
		}),
	);

	return response.payload?.data;
};

/** Clears activeBasket object and resets various other basket states */
export const activeBasketFromFullBasket = () => async (dispatch: Dispatch) => {
	await dispatch(ACTIVE_BASKET_FROM_FULL_BASKET());
};

/** Set active basket hash */
export const setActiveHash = (hash: string) => async (dispatch: Dispatch) => {
	await dispatch(SET_ACTIVE_HASH(hash));
};

/** Set tab number on active basket */
export const setActiveTabNumber = (tabNumber: string) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_ACTIVE_TAB_NUMBER(tabNumber));
};

/** Set active basket check in page */
export const setActiveCheckInPage = (page: TCheckInPage) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_ACTIVE_CHECK_IN_PAGE(page));
};

/** Set active basket isValidated state */
export const setActiveBasketValidated = (isValidated: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_ACTIVE_BASKET_VALIDATED(isValidated));
};

/** Set has basked updated state */
export const setHasBasketUpdated = (hasUpdated: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_HAS_BASKET_UPDATED(hasUpdated));
};

/** Show/hide card required prompt */
export const setCardRequiredPromptVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_CARD_REQUIRED_PROMPT_VISIBILITY(visible));
};

export const setOrderingAgain = (value: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_ORDERING_AGAIN(value));
};

export const clearBasketItems = () => async (dispatch: Dispatch) => {
	await dispatch(CLEAR_BASKET_ITEMS());
};

export const setOrderNoteSheetVisibility = (visible: boolean) => (
	dispatch: Dispatch,
) => {
	dispatch(SET_ORDER_NOTE_SHEET_VISIBILITY(visible));
};

export const setOrderNote = (note?: string) => (dispatch: Dispatch) => {
	dispatch(SET_ORDER_NOTE(note));
};

export const setItemNoteSheetVisibility = (visible: boolean) => (
	dispatch: Dispatch,
) => {
	dispatch(SET_ITEM_NOTE_SHEET_VISIBILITY(visible));
};

export const setItemNote = (itemClientId: string, note?: string) => (
	dispatch: Dispatch,
) => {
	dispatch(SET_ITEM_NOTE({ itemClientId, note }));
};

export const setFetchingBasket = (value: boolean) => (dispatch: Dispatch) => {
	dispatch(SET_FETCHING_BASKET(value));
};

/** Removes the orders from fullbasket in the state. Used for resetting state for order again. */
export const clearBasketOrders = () => (dispatch: Dispatch) =>
	dispatch(CLEAR_BASKET_ORDERS());

/** Change visibility of the age restricted bottomsheet */
export const setAgeRestrictedVisible = (value: boolean) => (
	dispatch: Dispatch,
) => dispatch(SET_AGE_RESTRICTED_VISIBLE(value));

/** Sets the vouchers in the active basket. Pass no argument, (or undefined) to clear */
export const setBasketVouchers = (vouchers?: IVoucher[]) => async (
	dispatch: Dispatch,
) => dispatch(SET_BASKET_VOUCHERS(vouchers));

/** Sets the discount card to be used in the active basket */
export const setBasketDiscount = (discountCard: IBasketDiscountCard) => async (
	dispatch: Dispatch,
) => dispatch(SET_BASKET_DISCOUNT(discountCard));

export const setPosOrderId = (posOrderId?: string) => (dispatch: Dispatch) => {
	dispatch(SET_POS_ORDER_ID(posOrderId));
};

/** Sets the tastecard token to be used in the active basket */
export const setBasketTastecard = (token: string) => async (
	dispatch: Dispatch,
) => {
	const basketReferral = {
		source: EReferralSource.tastecard,
		token,
	} as IBasketReferral;
	dispatch(SET_BASKET_REFERER(basketReferral));
};

/**
 * Used to overwrite the fullbasket.order object. Used by Pay and Go
 * TODO: refactor Pay and Go so we don't need to do this!
 */
export const setFullBasketOrder = (order: IOrder) => (dispatch: Dispatch) =>
	dispatch(SET_FULL_BASKET_ORDER(order));

/**
 * Function to handle setting/removing the item note on an item
 * @param state current basket state
 * @param PayloadAction
 * @returns basket state
 */
const handleSetItemNote = (
	state: IBasketState,
	action: PayloadAction<{ itemClientId: string; note?: string }>,
): IBasketState => {
	const { items } = state;
	const { itemClientId, note } = action.payload;

	// Return if somehow there's no items
	if (items.length === 0) {
		return state;
	}

	// map items and update item note where applicable
	const updatedItems = items.map((item) =>
		item.clientId && item.clientId === itemClientId
			? { ...item, itemNotes: note }
			: item,
	);

	// Return state
	return {
		...state,
		items: updatedItems,
	};
};

/**
 * Polls the basket, without changing local state or
 *
 * TODO: create a polling endpoint on the backend which just returns the basket hash to use to compare against
 */
export const pollBasket = (
	basketId: string,
	cancelTokenSource?: CancelTokenSource,
) => async (dispatch: Dispatch): Promise<undefined | IBasket> => {
	try {
		const req = await dispatch(
			POLL_BASKET({
				request: {
					method: 'get',
					url: `2/basket/${basketId}`,
					timeout: process.env.REACT_APP_POS_TIMEOUT,
					cancelToken: cancelTokenSource?.token,
					params: { polling: true },
				},
			}),
		);
		return (req?.payload?.data as IBasket) || undefined;
	} catch (_) {
		// As it's just polling, it should fail silently
		return undefined;
	}
};

export const postDiscountCard = (basketId: string, code: string) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		POST_DISCOUNT_CARD({
			request: {
				method: 'post',
				url: `/1/basket/${basketId}/discount-card`,
				data: { code },
			},
		}),
	);

	return response.payload?.data;
};

export default basketSlice.reducer;
