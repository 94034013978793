import { IActiveBasket, IBasket } from 'modules/basket/basket.types';

/** Add tip amount to amount to pay */
const calculateAmountToPay = (
	activeBasket: IActiveBasket,
	fullBasket: IBasket,
	includeTip: boolean = true,
	loyaltyDiscount: number = 0,
) => {
	const paymentSummary = fullBasket?.order?.paymentSummary
		? fullBasket.order.paymentSummary
		: fullBasket.paymentSummary;

	const isPaidInFull =
		fullBasket.order?.paymentSummary &&
		fullBasket.order.paymentSummary.totalOutstanding &&
		fullBasket.order.paymentSummary.totalOutstanding.units === 0;

	if (isPaidInFull) {
		return 0;
	}

	const activeTip = includeTip ? activeBasket.tipAmount?.amount?.units ?? 0 : 0;

	return (paymentSummary?.amountToPay.units ?? 0) + activeTip - loyaltyDiscount;
};

export default calculateAmountToPay;
